import Vue from "vue"
import axios from "~axios"

let localizationSwitcher = document.querySelector(".landing-localization-switcher")

// Set global translator method
Vue.mixin({
    data() {
        return {
            activetab: 2
        }
    },
    methods: {
        moreOpen(event) {
            event.target.parentNode.classList.toggle("open")
        },
        planOpen(event) {
            event.target.classList.toggle("remove")
            event.target.parentNode.parentNode.querySelector(".information-plan__list").classList.toggle("extra")
        }
    }
})

if (document.getElementById("localizationSwitcher")) {
    new Vue({
        el: "#localizationSwitcher",
        components: {
            LocalizationSwitcher: () => import("@components/LocalizationSwitcher")
        },
        methods: {
            close() {
                localizationSwitcher.style.display = "none"
            }
        }
    })
}

if (document.getElementById("courseAccessTypes")) {
    new Vue({
        el: "#courseAccessTypes",
        components: {
            CourseAccessTypes: () => import("@components/Course/CourseAccessTypes")
        }
    })
}

if (document.getElementById("courseProgramHandler")) {
    new Vue({
        el: "#courseProgramHandler",
        components: {
            LessonProgramHandler: () => import("@lesson/components/LessonProgramHandler.vue")
        }
    })
}

if (document.getElementById("navButtons")) {
    new Vue({
        el: "#navButtons",
        components: {
            LessonNavButtons: () => import("@lesson/components/LessonNavButtons.vue")
        }
    })
}

if (document.getElementById("navButtons2")) {
    new Vue({
        el: "#navButtons2",
        components: {
            LessonNavButtons: () => import("@lesson/components/LessonNavButtons.vue")
        }
    })
}

if (document.getElementById("userAvatar")) {
    new Vue({
        el: "#userAvatar",
        components: {
            UserAvatar: () => import("@components/UserAvatar")
        }
    })
}

const btnLogOut = document.querySelectorAll("[data-btn-log-out]")
if (document.querySelector(".user-menu") || btnLogOut.length) {
    for (let i = 0; btnLogOut.length > i; i++) {
        btnLogOut[i].addEventListener("click", event => {
            event.preventDefault()

            const headers = new Headers()
            let token = localStorage.getItem("token")

            if (!token || token !== window.token) token = window.token

            headers.append("Authorization", `Bearer ${token}`)

            fetch("/api/v1/auth/logout", {
                method: "get",
                headers
            })
                .then(() => {
                    // localStorage.removeItem('token')
                    location.reload()
                })
                .catch(err => console.log("Logout error:", err))
        })
    }
}

// Header hamburger menu logic
const menuCheckbox = document.getElementById("menu-btn")
// const header = document.getElementById("header")
const closeIcon = document.getElementById("close-menu")
const openIcon = document.getElementById("open-menu")
// const logo = document.querySelector(".header__logo")
// const headerRegisterBtn = document.getElementById("headerRegisterBtn")
// const authCabinetBtn = document.getElementById("authCabinetBtn")

if (menuCheckbox) {
    menuCheckbox.addEventListener("change", function () {
        if (this.checked) {
            // header.style.background = 'white';
            openIcon.style.display = "none"
            closeIcon.style.display = "block"
            // logo.classList.add('header__logo_black');
            // if (headerRegisterBtn) {
            //     headerRegisterBtn.style.display = 'none';
            // }
            // if (authCabinetBtn) {
            //     authCabinetBtn.style.display = 'none';
            // }
        } else {
            setTimeout(() => {
                // header.style.background = null;
                // logo.classList.remove('header__logo_black');
                // if (headerRegisterBtn) {
                //     if (window.innerWidth > 480) {
                //         headerRegisterBtn.style.display = 'initial';
                //     } else {
                //         headerRegisterBtn.style.display = 'none';
                //     }
                // }
                // if (authCabinetBtn) {
                //     authCabinetBtn.style.display = 'initial';
                // }
            }, 400)
            openIcon.style.display = "block"
            closeIcon.style.display = "none"
        }
    })
}

// window.addEventListener('resize', () => {
//     if (window.innerWidth > 1000) {
//         menuCheckbox.checked = false;
//         header.style.background = null;
//         if (openIcon) openIcon.style.display = 'block';
//         if (closeIcon) closeIcon.style.display = 'none';
//         if (!auth) {
//             logo.classList.remove('header__logo_black');
//             if (headerRegisterBtn) {
//                 headerRegisterBtn.style.display = 'initial';
//             }
//         } else {
//             if (authCabinetBtn) {
//                 authCabinetBtn.style.display = 'initial';
//             }
//         }
//     }
//     if (window.innerWidth <= 480) {
//         if (headerRegisterBtn) {
//             headerRegisterBtn.style.display = 'none';
//         }
//     } else if (window.innerWidth > 480) {
//         if (headerRegisterBtn) {
//             if (menuCheckbox.checked) {
//                 headerRegisterBtn.style.display = 'none';
//             } else {
//                 headerRegisterBtn.style.display = 'initial';
//             }
//         }
//     }
// })

function getActiveExpertCabinet(value = "") {
    let token = localStorage.getItem("token")
    if (!token || token !== window.token) token = window.token
    axios
        .get("/api/v1/cabinets", {
            headers: {
                Authorization: "Bearer " + token
            }
        })
        .then(response => {
            const cabinets = response.data.data
            let activeCabinet

            cabinets.forEach(item => {
                if (item.is_active) activeCabinet = item
            })

            if (activeCabinet) {
                window.location = `${window.location.protocol}//${activeCabinet.slug}.${window.location.hostname}${window.app_locale}/expert${value}?token=${token}`
            } else {
                window.location = window.app_locale + "/profile"
            }
        })
}

if (document.querySelector("#redirectExpertCabinet")) {
    let el = document.querySelector("#redirectExpertCabinet")

    el.addEventListener("click", e => {
        e.preventDefault()
        getActiveExpertCabinet()
    })
}

function changeLanguage(val) {
    window.location = `/locale/${val}`
}

window.changeLanguage = changeLanguage

function openLocalizationSwitcher() {
    localizationSwitcher.style.display = "block"
}

window.openLocalizationSwitcher = openLocalizationSwitcher

//slider for block in landing-pade chat-bots
let img_chat = document.querySelector(".home-page_products__img7")

if (img_chat) {
    let section1 = window.pageYOffset + document.querySelector("#section1").getBoundingClientRect().top
    let section2 = window.pageYOffset + document.querySelector("#section2").getBoundingClientRect().top
    let section3 = window.pageYOffset + document.querySelector("#section3").getBoundingClientRect().top
    let section4 = window.pageYOffset + document.querySelector("#section4").getBoundingClientRect().top
    let section5 = window.pageYOffset + document.querySelector("#section5").getBoundingClientRect().top

    let scrollOffset = 300

    window.addEventListener("scroll", function () {
        let scroll = window.pageYOffset + scrollOffset

        if (scroll > section1 && scroll < section2) {
            img_chat.setAttribute("src", "/build/img/list5.png")
        }

        if (scroll > section2) {
            img_chat.setAttribute("src", "/build/img/list4.png")
        }

        if (scroll > section3) {
            img_chat.setAttribute("src", "/build/img/list3.png")
        }

        if (scroll > section4) {
            img_chat.setAttribute("src", "/build/img/list2.png")
        }
        if (scroll > section5) {
            img_chat.setAttribute("src", "/build/img/list1.png")
        }
    })
}

var maybe_btn = document.querySelector(".subscribe_btn.maybe"),
    unsubscr_btn = document.querySelector(".un.subscribe_btn"),
    unsubscribed_block = document.querySelector(".unsubscribed_block"),
    subscribed_block = document.querySelector(".subscribed_block"),
    maybe = document.querySelector(".unsubscribed_block.maybe"),
    url_string = window.location.href,
    url = new URL(url_string),
    email = url.searchParams.get("email"),
    email_hash = url.searchParams.get("email_hash"),
    cabinet_hash = url.searchParams.get("cabinet_hash")

if (unsubscr_btn) {
    unsubscr_btn.addEventListener("click", function () {
        axios
            .post(`/api/v1/unsubscribe`, {
                email: email,
                email_hash: email_hash,
                cabinet_hash: cabinet_hash
            })
            .then(response => {
                let data = response.data
                console.log("unsubscr_btn data", data)
            })
        unsubscribed_block.style.display = "none"
        maybe.style.display = "block"
    })
}

if (maybe_btn) {
    maybe_btn.addEventListener("click", function () {
        axios
            .post(`/api/v1/unsubscribe/cancel`, { email: email, email_hash: email_hash, cabinet_hash: cabinet_hash })
            .then(response => {
                let data = response.data
                console.log("maybe_btn", data)
            })
        maybe.style.display = "none"
        subscribed_block.style.display = "block"
    })
}

let anchor = document.getElementById("toTop")
if (anchor) {
    if (window.location.pathname.indexOf("/blog/") >= 0) {
        document.addEventListener("scroll", () => {
            let anchor = document.getElementById("toTop")
            let offsetFromScreenTop = document
                .querySelector(".text-blog-header__time-wrapper")
                .getBoundingClientRect().y
            if (offsetFromScreenTop < 0) {
                anchor.classList.add("visible-top-btn")
            } else {
                anchor.classList.remove("visible-top-btn")
            }
        })
    }
    anchor.addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    })
}
